<template lang="html">
  <div
    v-if="instagramStories.length > 0"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
      intersection: {
        rootMargin: '0px 0px 600px 0px',
      },
    }"
    class="ui deliverable instagram_stories-card fluid card"
  >
    <template v-if="!isVisible">
      <div class="content">
        <div class="ui placeholder">
          <div class="image header">
            <div class="line" />
            <div class="line" />
          </div>
        </div>
      </div>
      <div class="image">
        <div class="ui placeholder">
          <div class="square image" />
        </div>
      </div>
      <div class="extra content">
        <div class="ui placeholder">
          <div class="short line" />
        </div>
      </div>
    </template>
    <template v-if="isVisible">
      <div class="content center aligned card-header">
        <span class="icon-head">
          <i class="icon colored instagram-stories" />
        </span>
        <div class="header ui tiny statistics">
          <div class="statistic">
            <div v-if="groupedDeliverablesTotalStats.length > 1" class="value">
              <span>
                {{ groupedDeliverablesTotalStats[0].value.toLocaleString() }}
              </span>
            </div>
            <div class="label">
              {{ groupedDeliverablesTotalStats[0].type }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="groupedDeliverablesTotalStats.length > 1"
        class="ui content statistics-head"
      >
        <div class="ui equal width grid">
          <div class="ui column tiny statistic">
            <div class="value">
              <span>{{ instagramStories.length }}</span>
            </div>
            <div class="label">
              <span>Stories</span>
            </div>
          </div>
          <div
            v-for="statisticValue in groupedDeliverablesTotalStats.slice(1)"
            v-if="
              statisticValue.value != 0 && statisticValue.value != '00:00:00'
            "
            class="ui column tiny statistic"
          >
            <div class="value">
              <span>{{ statisticValue.value.toLocaleString() }}</span>
            </div>
            <div class="label">
              <span>{{ statisticValue.label }}</span>
            </div>
          </div>
        </div>
      </div>

      <carousel
        :per-page="1"
        :navigation-enabled="true"
        :pagination-enabled="false"
        navigation-next-label="<i class='icon large grey chevron right'></i>"
        navigation-prev-label="<i class='icon large grey chevron left'></i>"
      >
        <slide v-for="(deliverable, i) in sortedStories">
          <div class="ui carousel content">
            <div class="ui content card-content card-content-res">
              <div class="ui equal width padded grid card-content-res-grid">
                <div class="column card-content-res-column1">
                  <instagram-story
                    :key="deliverable.id"
                    :deliverable="deliverable"
                    :selected="false"
                    :selectable="false"
                    video-control-selector=".overlay.top"
                    @openModal="openModal"
                  />
                </div>

                <div class="column card-content-res-column2">
                  <div v-if="deliverable.statisticsValues.length > 1" class="">
                    <h4>Insights:</h4>

                    <p class="date">
                      {{ deliverable.provider_published_at | formatDateTime }}
                    </p>

                    <div
                      v-for="statisticValue in deliverable.statisticsValues"
                      v-if="
                        statisticValue.total.value != 0 &&
                        statisticValue.total.value != '00:00:00'
                      "
                      class="ui mini statistic"
                    >
                      <div class="value">
                        <span>{{
                          statisticValue.total.value.toLocaleString()
                        }}</span>
                      </div>
                      <div class="ui tiny popup">
                        <div class="ui list">
                          <div
                            v-for="detail in statisticValue.details"
                            class="item"
                          >
                            {{ detail }}
                          </div>
                        </div>
                      </div>
                      <div class="label">
                        <span
                          v-if="
                            statisticValue.total.label === 'engagements' &&
                            statisticValue.details.length < 2
                          "
                          >Replies</span
                        >
                        <span v-else>{{ statisticValue.total.label }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="instagramStories.length > 1"
              class="content center aligned pagination-label"
            >
              <span> {{ i + 1 }}/{{ instagramStories.length }} </span>
            </div>
          </div>
        </slide>
      </carousel>
    </template>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import IntersectionObserver from "intersection-observer";
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);

import _ from "lodash";
import { Carousel, Slide } from "vue-carousel";
import InstagramStory from "views/deliverables/components/instagramStory.vue";

const { moment, $ } = window;

export default {
  components: {
    Carousel,
    Slide,
    InstagramStory,
  },
  props: {
    instagramStories: {
      type: Array,
    },
    lazyLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const $this = this;
    return {
      isVisible: $this.lazyLoad ? false : true,
    };
  },
  computed: {
    sortedStories() {
      return _.sortBy(this.instagramStories, (d) =>
        moment(d.provider_published_at)
      );
    },
    groupedDeliverablesTotalStats() {
      return ["impressions", "impressions_unique", "engagements"].map(
        (type) => {
          let typeLabel = type;
          const total = _.sumBy(this.instagramStories, (deliverable) => {
            let typeStats = _.filter(
              deliverable.statisticsValues,
              (stat) => stat.type === type
            );

            if (typeStats[0]) {
              typeLabel = typeStats[0].total.label;
            }

            // if (type === "engagements") {
            //   typeLabel = "Replies";
            // }

            const typeTotal = _.sumBy(typeStats, (stat) => stat.total.value);

            return typeTotal;
          });
          return {
            label: typeLabel,
            type,
            value: total,
          };
        }
      );
    },
  },
  updated() {
    const $this = this;
    this.$nextTick(() => {
      $this.initPopups();
    });
  },
  methods: {
    initPopups() {
      $(this.$el).find(".value").popup({
        position: "left center",
      });
    },
    visibilityChanged(isVisible) {
      this.isVisible = this.isVisible || isVisible;
    },
    openModal(deliverable) {
      this.$emit("openModal", deliverable);
    },
  },
};
</script>

<style scoped lang="scss">
.carousel {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;

  .ui.content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

/deep/ {
  .VueCarousel {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  }

  .VueCarousel-wrapper,
  .VueCarousel-inner {
    height: 100%;
  }

  .VueCarousel-navigation {
    position: absolute;
    bottom: 45px;
    width: 100%;
  }

  .VueCarousel-navigation-prev {
    /* transform: translateY(-80%) translateX(400%) !important; */
    top: 0 !important;
    left: 35% !important;
    transform: none !important;
  }
  .VueCarousel-navigation-next {
    top: 0 !important;
    right: 35% !important;
    transform: none !important;
    /* transform: translateY(-80%) translateX(-400%) !important; */
  }

  .VueCarousel-slide {
    overflow: hidden;
  }
  .VueCarousel-slide .content {
    display: block;
  }
}

.instagram_stories-card.card {
  h4 {
    color: #0d0d0d;
    font-size: 1.142rem;
    margin-bottom: 0.4em;
    font-weight: 500;
  }

  p.date {
    color: #7a7a7a;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
  }

  .mini.statistic {
    display: flex;
    margin-left: 0;
    margin-bottom: 1em;
    > .value,
    > .label {
      text-align: left;
    }
    > .value {
      color: #0d0d0d;
      line-height: 1.19em !important;
      font-size: 1.71rem !important;
      font-weight: 600;
    }
    > .label {
      font-weight: 500;
    }
  }

  .content.center.aligned.pagination-label {
    color: #878787;
    font-weight: 600;
    flex-grow: 0;
    flex-shrink: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .card-content-res {
      height: fit-content;
    }
    .card-content-res-grid {
      display: block;
    }
    .card-content-res-column2 {
      display: flex !important;
    }
  }
}
</style>
