<template lang="html">
  <div v-show="!statistic['_destroy']" class="clearing">
    <div class="ui inline fields">
      <div
        class="ui wide field"
        :class="{ eleven: !isUserEntered, seven: isUserEntered }"
      >
        <select
          v-model="metricTypeLookup"
          class="ui dropdown search selection nowrap fluid"
          data-additions="true"
          @change="save"
        >
          <option value="">Type a Metric Name</option>
          <option
            v-for="label in Object.keys(metricTypeDictionary).sort()"
            :value="label"
          >
            {{ label }}
          </option>
        </select>
      </div>

      <div v-show="isUserEntered" class="ui four wide field">
        <select
          @change="save"
          v-model="statistic.metric_type"
          placeholder="Metric Type"
        >
          <option v-for="option in metricTypes" :value="option">
            {{ option }}
          </option>
        </select>
      </div>

      <div class="ui four wide field">
        <input
          v-model="statistic.value"
          :type="inputTypeFromMetricType"
          placeholder="Value"
          @blur="save"
        />
      </div>
      <div class="one wide field">
        <span v-if="saving">
          <span class="ui active inline loader" />
        </span>
        <a
          v-else
          class="ui pointer grey"
          :class="{
            disabled: !statistic.value || !statistic.metric_type,
          }"
          @click.stop.prevent="save"
        >
          <i class="icon save fitted" />
        </a>

        <a
          v-if="statistic.id && !saving"
          class="ui pointer"
          @click.stop.prevent="remove"
        >
          <span class="ui text grey"><i class="icon times fitted" /></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
// import SuiVue from "semantic-ui-vue";
import { debounce } from "lodash";

// Vue.use(SuiVue);

const $ = window.jQuery;

export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
    metricTypes: {
      type: Array,
    },
    allowAdditions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const $this = this;
    return {
      statistic: $this.value,
      saving: false,
    };
  },

  computed: {
    isUserEntered() {
      return (
        this.metricTypeLookup != null &&
        !Object.keys(this.defaultMetricTypes).includes(this.metricTypeLookup)
      );
    },
    metricTypeLookup: {
      get() {
        return (
          this.statistic && this.statistic.label
          //&& this.$options.filters.humanize(this.statistic.label)
        );
      },
      set(newVal) {
        if (!newVal) {
          return;
        } else if (this.metricTypeDictionary[newVal]) {
          this.$set(
            this.statistic,
            "metric_type",
            this.metricTypeDictionary[newVal]
          );
          this.$set(this.statistic, "label", newVal);
        } else {
          this.$set(this.statistic, "metric_type", "other");
          this.$set(this.statistic, "label", newVal);
        }
      },
    },
    defaultMetricTypes() {
      let hash = {
        "Link Clicks": "engagements",
        "Sticker Taps": "engagements",
        "Swipe Ups": "engagements",
        Comments: "engagements",
        Impressions: "impressions",
        Likes: "engagements",
        Reach: "impressions_unique",
        Replies: "engagements",
        Saves: "engagements",
        Shares: "engagements",
        Views: "views",
      };

      this.metricTypes.map((type) => {
        hash[this.$options.filters.humanize(type)] = type;
      });
      return hash;
    },
    metricTypeDictionary() {
      let hash = { ...this.defaultMetricTypes };
      if (
        this.statistic &&
        this.statistic.label &&
        this.statistic.metric_type
      ) {
        hash[this.statistic.label] = this.statistic.metric_type;
      }

      return hash;
    },
    inputTypeFromMetricType() {
      switch (this.statistic.metric_type) {
        case "impressions":
        case "engagements":
        case "shares":
        case "views":
        case "impressions_unique":
          return "number";
        default:
          return "text";
      }
    },
    endpoint() {
      return "/statistics";
    },
    url() {
      let url = `${this.endpoint}`;
      if (this.statistic.id) {
        url += `/${this.statistic.id}`;
      }
      return url;
    },
    requestType() {
      let type = `post`;
      if (this.statistic.id) {
        type = `patch`;
      }
      return type;
    },
  },
  watch: {
    // "statistic.label": function (newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.debouncedSave();
    //   }
    // },
    // "statistic.metric_type": function (newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.debouncedSave();
    //   }
    // },
    // "statistic.value": function (newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.debouncedSave();
    //   }
    // },
  },
  mounted() {
    this.statistic.manual_update = true;
    let $this = this;
    $(this.$el)
      .find(".dropdown")
      .dropdown({
        allowAdditions: this.allowAdditions,
        hideAdditions: false,
        showOnFocus: false,
        clearable: true,
        onChange(value) {
          if (value == "") {
            // $this.$set(this.statistic, "metric_type", null);
            // $this.$set(this.statistic, "label", null);
          }
        },
      })
      .data("dropdown-loaded", true);
  },
  methods: {
    remove() {
      const $this = this;
      if (window.confirm("Are you sure you want to delete this statistic?")) {
        window.$.ajax({
          url: $this.url,
          type: "delete",
          dataType: "json",
          data: {
            id: $this.statistic.id,
          },
          success: function (data) {
            $this.$emit("remove", $this.statistic);
          },
        });
      }
    },
    debouncedSave: debounce(function () {
      this.save();
    }, 5000),
    save: function () {
      const $this = this;
      if (this.statistic.metric_type && this.statistic.value) {
        this.statistic.manual_update = true;
        // this.$emit("save", this.statistic);

        $this.saving = true;
        window.$.ajax({
          url: $this.url,
          type: $this.requestType,
          dataType: "json",
          data: {
            statistic: $this.statistic,
          },
          success: function (data) {
            $this.$set($this.statistic, "id", data.id);
            $this.$emit("input", $this.statistic);
            $this.$emit("change", $this.statistic);
            $this.saving = false;
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
