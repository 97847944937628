<template lang="html">
  <div
    v-if="hasBeenLoaded"
    :id="`deliverableCard${deliverable.id}`"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
      intersection: {
        rootMargin: '1000px',
      },
    }"
    class="ui deliverable card fluid"
    :class="[`${deliverable.inferred_type}-card`, { fluid }]"
  >
    <!-- <a @click="loadData">Reload</a> -->
    <template v-if="!isVisible">
      <div class="content">
        <div class="ui placeholder">
          <div class="image header">
            <div class="line" />
            <div class="line" />
          </div>
        </div>
      </div>
      <div class="image">
        <div class="ui placeholder">
          <div class="square image" />
        </div>
      </div>
      <div class="extra content">
        <div class="ui placeholder">
          <div class="short line" />
        </div>
      </div>
    </template>

    <template v-if="isVisible">
      <div
        v-if="!editable || showCardHeader"
        class="content center aligned card-header"
      >
        <span class="icon-head">
          <i v-if="incomplete" class="ui icon wait" />
          <i
            v-if="!incomplete && !favicon"
            class="icon colored"
            :class="inferredTypeAsIconClass(deliverable.inferred_type)"
          />
          <img
            v-if="!incomplete && favicon"
            :src="favicon"
            height="55"
            width="55"
          />
        </span>
        <div class="header ui tiny statistics">
          <div
            v-if="
              deliverable.statisticsValues &&
              deliverable.statisticsValues.length > 0
            "
            class="statistic"
          >
            <div v-if="headerStat" class="value">
              <span>
                <i
                  class="ui icon dollar fitted"
                  v-if="headerStat.type == 'revenue'"
                ></i>
                {{ headerStat.total.value | abbreviateNumber }}
              </span>
            </div>
            <div
              v-if="headerStat && headerStat.details.length > 0"
              class="ui tiny popup desktop-only"
            >
              <div class="ui list">
                <div v-for="detail in headerStat.details" class="item">
                  {{ detail }}
                </div>
              </div>
            </div>
            <div
              v-if="headerStat.total && headerStat.total.value > 0"
              class="label"
            >
              {{ headerStat.total.label }}
            </div>
          </div>
          <div v-else class="statistic">
            <div class="value">&nbsp;</div>
            <div class="label">&nbsp;</div>
          </div>
        </div>
      </div>

      <div v-if="shouldShowSecondaryStats" class="ui content statistics-head">
        <!-- Details Popup -->
        <div ref="statisticsDetails" class="ui wide popup desktop-only">
          <div
            v-for="group in statisticsDetails"
            v-if="group.details.length > 0"
            class="ui statistic margin-left-zero left aligned"
          >
            <div class="label">
              {{ group.total.label }}
            </div>
            <div class="ui list margin-top-tiny margin-bottom-small">
              <div v-for="detail in group.details" class="ui item">
                {{ detail }}
              </div>
            </div>
          </div>

          <span class="ui small text">
            <a
              href="https://help.influencekit.com/en/articles/2138024-understanding-report-metrics"
              target="_blank"
            >
              <i class="icon question mark circle" />
              What do these mean?
            </a>
          </span>
        </div>

        <div class="ui equal width grid">
          <div
            v-for="statisticValue in secondaryStats.slice(0, 3)"
            v-if="
              statisticValue.total.value != 0 &&
              statisticValue.total.value != '00:00:00'
            "
            class="ui column tiny statistic"
          >
            <div class="value">
              <span>{{ statisticValue.total.value | abbreviateNumber }}</span>
            </div>
            <!--             <div class="ui tiny popup">
              <div class="ui list">
                <div class="item" v-for="detail in statisticValue.details">
                  {{ detail }}
                </div>
              </div>
            </div>
 -->
            <div class="label">
              <span>{{ statisticValue.total.label }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="content card-content">
        <div
          v-if="searchStats && searchStats.total.count > 0"
          style="position: absolute; width: 75%; z-index: 20"
        >
          <div class="ui label left status corner search stat">
            <i class="icon yellow circular seo-trophy inverted" />
          </div>
          <div class="ui fluid inverted popup search">
            <div class="ui list">
              <div v-for="detail in searchStats.details" class="item">
                {{ detail }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          v-if="positiveComments && positiveComments.total.count > 0"
          style="position: absolute; width: 75%; z-index: 20"
        >
          <div class="ui label left status corner search stat">
            <i class="icon pink circular comments inverted" />
          </div>
          <div class="ui fluid inverted popup search">
            Top Comments:
            <div class="ui list margin-top-small">
              <div v-for="detail in positiveComments.details" class="item">
                {{ detail }}
              </div>
            </div>
          </div>
        </div> -->

        <h3 v-if="!deliverable.url" class="margin-top-zero">
          {{ deliverable.name }}
        </h3>

        <div v-if="incomplete" class="center aligned content">
          <br /><br /><br />
          <i class="ui grey huge icon wait" />
          This deliverable is incomplete...
        </div>

        <div v-if="deliverable.description" v-html="deliverable.description" />

        <carousel
          ref="carousel"
          :per-page="1"
          :pagination-enabled="false"
          :navigation-enabled="true"
          :mouse-drag="false"
          :touch-drag="false"
          navigation-next-label="<i class='icon large grey chevron right'></i>"
          navigation-prev-label="<i class='icon large grey chevron left'></i>"
        >
          <slide v-if="showIframelyEmbed">
            <div class="ui carousel content">
              <div
                v-if="!isPushNotificationType"
                class="iframely-embed centered"
              >
                <div class="iframely-responsive">
                  <a
                    v-if="
                      ['mailchimp', 'email'].includes(
                        deliverable.inferred_type
                      ) ||
                      (!['shopify', 'pinterest'].includes(
                        deliverable.inferred_type
                      ) &&
                        !isEmailType)
                    "
                    ref="iframelyLink"
                    :href="urlOrExpandedUrl"
                    :data-iframely-url="iframelyUrl"
                  />

                  <img
                    v-if="
                      deliverable.inferred_type === 'pinterest' && iframelyData
                    "
                    :src="iframelyData.links.thumbnail[0].href"
                  />

                  <turbo-frame
                    :id="`deliverable_${deliverable.id}_preview`"
                    v-if="
                      deliverable.inferred_type === 'activecampaign' ||
                      deliverable.inferred_type === 'drip' ||
                      deliverable.inferred_type === 'klaviyo' ||
                      deliverable.inferred_type === 'constantcontact' ||
                      deliverable.inferred_type === 'campaignmonitor' ||
                      deliverable.inferred_type === 'shopify'
                    "
                    :src="`/deliverables/${deliverable.id}/preview`"
                    loading="lazy"
                    class="ui 9:16"
                    style="background: #fff"
                  ></turbo-frame>

                  <div
                    v-if="
                      isEmailType &&
                      deliverable.inferred_type != 'activecampaign' &&
                      deliverable.inferred_type != 'klaviyo' &&
                      deliverable.inferred_type != 'constantcontact' &&
                      deliverable.inferred_type != 'campaignmonitor'
                    "
                    class="center aligned"
                  >
                    <br /><br /><br /><br />
                    <i class="icon envelope open outline massive disabled" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="
                isPushNotificationType && deliverable.provider_caption != null
              "
              v-html="deliverable.provider_caption"
            />
          </slide>

          <slide v-if="isInstagramStoryType">
            <div class="ui carousel content center aligned">
              <instagram-story-embed
                :deliverable="deliverable"
                style="width: 55%; margin: 0px auto"
              />
            </div>
          </slide>

          <slide
            :key="`${upload.id}-${upload.position}`"
            v-for="(upload, i) in deliverable.uploads"
            v-if="deliverable.uploads.length > 0 && !isInstagramStoryType"
          >
            <div
              v-if="
                ['mp4', 'mov'].some((ext) => upload.expiring_url.includes(ext))
              "
              :id="'embed' + deliverable.id"
              class="ui video embed active 9:16"
              style="width: 40%; margin: 0px auto; padding-bottom: 71%"
              :data-url="upload.expiring_url"
            >
              <video
                :src="upload.expiring_url"
                width="100%"
                controls
                allow="autoplay"
              />
            </div>

            <div
              v-else-if="upload.expiring_url.includes('pdf') && !isCardPage"
              class="flex-wrap"
            >
              <iframe
                :src="`${upload.expiring_url}#toolbar=0&view=Fit`"
                width="100%"
                height="99%"
                frameborder="0"
              />
            </div>
            <div
              v-else-if="upload.expiring_url.includes('pdf') && isCardPage"
              class="flex-wrap"
            >
              <img
                :src="'data:image/png;base64,' + upload.pdf_thumbnail_base64"
              />
            </div>

            <div v-else class="flex-wrap">
              <a
                class="slide-image"
                :href="upload.expiring_url"
                target="_blank"
                :style="{
                  backgroundImage: `url(${upload.expiring_url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }"
              />
              <div
                v-if="deliverable.uploads.length > 1"
                class="center aligned content pagination-label"
              >
                <span> {{ i + 1 }}/{{ deliverable.uploads.length }} </span>
              </div>
            </div>
          </slide>
        </carousel>

        <a
          v-if="canShowMore && showMore"
          class="opener"
          @click.stop.prevent="openModal"
        >
          <i class="icon expand" />
        </a>
      </div>
      <div
        v-if="deliverable.url && !isInstagramStoryType"
        class="extra content"
      >
        <a
          v-if="showExternalLink"
          class="link"
          :href="externalLink"
          target="_blank"
        >
          <i class="icon external alternate" />

          <span v-if="deliverable.inferred_type === 'url'">View on Blog</span>
          <span v-if="deliverable.inferred_type === 'bitly'">View link</span>
          <span v-if="deliverable.inferred_type === 'short_io'">View link</span>

          <span v-if="isEmailType"
            >View <span class="provider">email</span></span
          >
          <span v-if="isPodcastType"
            >View <span class="provider">podcast</span></span
          >

          <span v-if="deliverable.inferred_type.toLowerCase().includes('other')"
            >View <span class="provider">link</span></span
          >
          <span
            v-else-if="
              !['email', 'url', 'bitly', 'short_io', 'other'].includes(
                deliverable.inferred_type.toLowerCase()
              ) &&
              !isEmailType &&
              !isPodcastType
            "
            >View on
            <span class="provider">
              {{
                inferredTypeAsIconClass(deliverable.inferred_type) | toCamelCase
              }}
            </span>
          </span>
        </a>
      </div>

      <div class="ui modal" />
      <span
        v-if="editable && !showStatusLabel"
        class="ui right corner label status"
        @click.alt="openReportingStatus"
      >
        <span data-forcewrap>
          <a @click="refreshStats">
            <i class="icon sync" :class="{ loading: refreshingStats }" />
          </a>
        </span>
      </span>

      <span
        v-if="showStatusLabel"
        class="ui right corner label"
        @click="refreshStats"
        @mouseover.once="loadReportingStatus"
        @click.alt="openReportingStatus"
      >
        <span
          v-if="deliverable.reporting_status"
          data-forcewrap
          :data-tooltip="deliverable.reporting_status.message"
          data-position="bottom center"
          data-variation="mini"
        >
          <i class="icon" :class="deliverable.reporting_status.icon" />
        </span>
        <span v-else><i class="icon question mark" /></span>
      </span>
    </template>
  </div>
</template>

<script>
import IntersectionObserver from "intersection-observer";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import store from "store";
import Vue from "vue/dist/vue.esm";
import { Carousel, Slide } from "vue-carousel";
import InstagramStoryEmbed from "views/deliverables/components/instagramStoryEmbed.vue";
import VueObserveVisibility from "vue-observe-visibility";
import ResizeObserver from "resize-observer-polyfill";
import Modal from "components/modal.vue";
import "functions/filters";

Vue.use(VueObserveVisibility);

const $ = window.jQuery;

export default {
  store,
  components: {
    InstagramStoryEmbed,
    Modal,
    Carousel,
    Slide,
  },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    initialDeliverable: Object,
    loadDataRemotely: {
      type: Boolean,
      default: false,
      description: "Load data for this card remotely",
    },
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    canShowMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const $this = this;
    return {
      modalCardComponent: null,
      modalCardData: null,
      isVisible: $this.lazyLoad ? false : true,
      favicon: false,
      showMore: false,
      hasBeenLoaded: !$this.loadDataRemotely,
      deliverable: { ...($this.initialDeliverable || {}) },
      iframelyData: null,
      refreshingStats: false,
    };
  },
  computed: {
    ...mapGetters("deliverable", ["inferredTypeAsIconClass"]),
    ...mapState("report", ["accessToken"]),
    editable() {
      return this.$store.state.report.editable;
    },
    showStatusLabel() {
      if (this.accessToken) {
        return false;
      }
      return (
        (this.editable && this.deliverable.last_error_at) ||
        !this.deliverable.last_fetched ||
        (this.deliverable.reporting_status &&
          (this.deliverable.reporting_status.status === "error" ||
            this.deliverable.reporting_status.status === "unknown"))
      );
    },
    showExternalLink() {
      return (
        this.deliverable.url &&
        this.deliverable.inferred_type != "convertkit" &&
        // this.deliverable.inferred_type != "drip" &&
        //this.deliverable.inferred_type != "activecampaign" &&
        this.deliverable.inferred_type != "madmimi" &&
        this.deliverable.inferred_type != "klaviyo" &&
        this.deliverable.inferred_type != "shopify" &&
        this.deliverable.inferred_type != "expertsender" &&
        !this.isPushNotificationType
      );
    },
    showCardHeader() {
      return true;
    },
    headerStat() {
      if (!this.deliverable.statisticsValues) {
        return false;
      }
      const index = this.deliverable.statisticsValues.findIndex(
        (value) => value.total.value > 0
      );

      if (index !== -1) {
        return this.deliverable.statisticsValues[index];
      }
      return false;
    },
    secondaryStats() {
      if (!this.deliverable.statisticsValues) {
        return false;
      }
      return _.reject(
        _.difference(this.deliverable.statisticsValues, [this.headerStat]),
        {
          type: "search_position",
          type: "positive_comments",
        }
      );
    },
    shouldShowSecondaryStats() {
      return (
        this.secondaryStats &&
        _.some(this.secondaryStats, (stat) => {
          return stat.total.value != "";
        })
      );
    },
    searchStats() {
      if (!this.deliverable.statisticsValues) {
        return false;
      }
      return _.find(this.deliverable.statisticsValues, {
        type: "search_position",
      });
    },
    positiveComments() {
      if (!this.deliverable.statisticsValues) {
        return false;
      }
      return _.find(this.deliverable.statisticsValues, {
        type: "positive_comments",
      });
    },
    statisticsDetails() {
      return this.deliverable.statisticsValues
        .map((s) => {
          return { name: s.type, details: s.details, total: s.total };
        })
        .flat();
    },
    incomplete() {
      return (
        !this.deliverable.url &&
        !this.deliverable.description &&
        this.deliverable.statisticsValues &&
        this.deliverable.statisticsValues.length < 1
      );
    },
    showIframelyEmbed() {
      return (
        this.deliverable.url &&
        !this.deliverable.hide_embed &&
        !this.isInstagramStoryType &&
        !this.hideEmailIcon
      );
    },
    iframelyUrl() {
      let url = `https://cdn.iframe.ly/api/iframe?&url=${encodeURIComponent(
        this.urlOrExpandedUrl
      )}&key=691c90188e4da53cfe4eaf8b181bcb57&autoplay=0`;
      if (!window.location.search.includes("eagerLoadEmbeds")) {
        url = `${url}&lazy=1`;
      }
      return url;
    },
    isCardPage() {
      return window.location.pathname.includes("/card");
    },
    iframelyApi() {
      return `https://cdn.iframe.ly/api/iframely?url=${encodeURIComponent(
        this.urlOrExpandedUrl
      )}&key=691c90188e4da53cfe4eaf8b181bcb57&autoplay=0`;
    },
    externalLink() {
      switch (this.deliverable.inferred_type) {
        case "activecampaign":
        case "drip":
          return `/deliverables/${this.deliverable.id}/preview`;
        default:
          return this.urlOrExpandedUrl;
      }
    },
    urlOrExpandedUrl() {
      return this.deliverable.url;
    },
    hostFromUrl() {
      const host = new URL(this.deliverable.url).host.replace("www.", "");
      return host;
    },
    isPodcastType() {
      return ["art19", "omny_studio", "transistor"].includes(
        this.deliverable.inferred_type
      );
    },
    isEmailType() {
      return [
        "email",
        "mailerlite",
        "mailchimp",
        "aweber",
        "convertkit",
        "drip",
        "campaignmonitor",
        "constantcontact",
        "sendfox",
        "madmimi",
        "activecampaign",
        "klaviyo",
        "expertsender",
        "flodesk",
      ].includes(this.deliverable.inferred_type);
    },
    isPushNotificationType() {
      return ["one_signal", "push_engage"].includes(
        this.deliverable.inferred_type
      );
    },
    isInstagramStoryType() {
      return ["instagram_stories"].includes(this.deliverable.inferred_type);
    },
    hideEmailIcon() {
      return this.isEmailType && this.hasDescriptionOrCaptionOrUploads;
    },
    hasDescriptionOrCaptionOrUploads() {
      return (
        !this.inferred_type === "mailchimp" &&
        (this.deliverable.provider_caption ||
          (this.deliverable.description &&
            this.deliverable.description != "") ||
          (this.deliverable.uploads && this.deliverable.uploads.length > 0))
      );
    },
  },
  watch: {
    initialDeliverable: {
      deep: true,
      handler(val) {
        let clone = { ...val }; // val is a reference to the prop,
        delete clone.statisticsValues; // don't mutate it here, or else we have weird side effects
        this.deliverable = { ...this.deliverable, ...clone };
      },
    },
    isVisible(val, oldVal) {
      if (val && val != oldVal) {
        this.$nextTick(() => {
          this.initCard();
        });
      }
    },
    showIframelyEmbed(val, oldVal) {
      if (val && val != oldVal) {
        this.$nextTick(() => {
          this.loadIframelyEmbed();
        });
      }
    },
  },
  mounted() {
    const $this = this;

    $this.$nextTick(() => {
      if ($this.loadDataRemotely) {
        $this.loadData();
      } else {
        $this.emitLoaded();
      }

      if (!this.lazyLoad) {
        $this.initCard();

        if (window.location.search.includes("eagerLoadEmbeds")) {
          $this.visibilityChanged(true);
        }
      }
    });
  },
  updated() {
    const $this = this;
    this.$nextTick(() => {
      $this.initPopups();
    });
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = this.isVisible || isVisible;
    },
    getIframelyResponse() {
      if (
        !this.iframelyData &&
        (this.deliverable.inferred_type === "url" ||
          this.deliverable.inferred_type === "pinterest" ||
          this.deliverable.inferred_type === "other")
      ) {
        $.get(this.iframelyApi).done((data) => {
          this.iframelyData = data;
          if (
            this.deliverable.inferred_type === "url" &&
            data.links &&
            data.links.icon
          ) {
            this.favicon = data.links.icon[0].href;
          }
          if (this.deliverable.inferred_type === "pinterest") {
            $(this.$el)
              .find(".iframely-responsive")
              .removeClass("iframely-responsive");
          }

          this.$nextTick(() => {
            this.setShowMore();
          });
        });
      }
    },
    openModal() {
      this.$emit("openModal", this.deliverable);
    },
    loadReportingStatus() {
      if (!this.deliverable.reporting_status) {
        $.getJSON(`/deliverables/${this.deliverable.id}/status`).done(
          (data) => {
            this.$set(
              this.deliverable,
              "reporting_status",
              data.reporting_status
            );
          }
        );
      }
    },
    openReportingStatus() {
      window.open(`/deliverables/${this.deliverable.id}/status`, "_blank");
    },
    setShowMore() {
      if (this.$el) {
        const cardContent = $(this.$el).find(".card-content")[0];
        this.showMore =
          cardContent && cardContent.scrollHeight > cardContent.clientHeight;
      }
    },
    observeEmbedResize() {
      const ro = new ResizeObserver(this.setShowMore);

      const embedEl = $(this.$el).find(
        ".embed-wrapper, .iframely-responsive, .iframely-embed, .card-content img"
      )[0];
      if (embedEl) {
        ro.observe(embedEl);
      }
    },
    loadData() {
      $.read(`/deliverables/${this.deliverable.id}.json`)
        .done((data) => {
          this.hasBeenLoaded = true;
          Object.assign(this.deliverable, data);
          // this.$set(this.deliverable, "statisticsValues", data.statisticsValues);
        })
        .then(() => {
          this.$forceUpdate();
          this.emitLoaded();
        });
    },
    refreshStats() {
      $.read(`/deliverables/${this.deliverable.id}/refresh`);
      this.refreshingStats = true;
    },
    emitLoaded() {
      this.$emit("cardLoaded", this.deliverable);
    },
    initPopups() {
      $(this.$el)
        .find(".card-header,.statistics-head")
        .popup({
          closable: false,
          observeChanges: true,
          popup: $(this.$refs.statisticsDetails),
          hoverable: true,
          position: "right center",
          forcePosition: false,
          lastResort: "right center",
          movePopup: true,
          target: $(this.$el).find(".content.statistics-head"),
          transition: "fade",
          delay: {
            show: 250,
            hide: 50,
          },
        });

      // $(this.$el)
      //   .find(".statistic .value")
      //   .popup({
      //     observeChanges: false,
      //     inline: true,
      //     hoverable: true,
      //     position: "top center",
      //     boundary: this.$el,
      //     context: this.$el,
      //     movePopup: false
      //   });

      $(this.$el)
        .find(".search.stat")
        .popup({
          observeChanges: false,
          inline: true,
          hoverable: true,
          position: "bottom left",
          boundary: $(this.$el).find(".card-content"),
          lastResort: true,
          offset: 8,
        });
    },
    initEmbeds() {
      $(this.$el)
        .find(".video.embed")
        .embed({
          icon: "video play",
          onCreate(url) {
            if (url.includes(".mov")) {
              $(this).find("iframe").remove();
            }
          },
        });
      $(this.$el)
        .find(".iframely-embed .ui.embed")
        .embed({
          autoplay: null,
          onDisplay() {
            const iframe = $(this).find("iframe")[0];
            $(this).addClass("scrolling");
            $(iframe).on("load", function () {
              const height = `${$(this).contents().height()}px`;
              $(iframe).height(height);
            });
          },
        });
    },
    initHeartbeat() {
      if (typeof deliverablesChannel !== "undefined") {
        const $this = this;
        window.deliverablesChannel(
          $this.deliverable.id,
          "deliverable-updated",
          function (data) {
            $this.loadData();
            $this.refreshingStats = false;
          }
        );

        window.deliverablesChannel(
          $this.deliverable.id,
          "deliverable-refresh-queued",
          function (data) {
            $this.deliverable.reporting_status =
              data.deliverable.reporting_status;
          }
        );
      }

      // if (typeof hb !== "undefined") {
      //   const channel = hb.pusher.subscribe(
      //     `deliverable-${this.deliverable.id}`
      //   );

      //   channel.bind("deliverable-refresh-queued", (data) => {
      //     this.deliverable.reporting_status = data.reporting_status;
      //   });
      //   channel.bind("deliverable-updated", () => {
      //     this.loadData();
      //     this.refreshingStats = false;
      //   });
      // }
    },
    loadIframelyEmbed() {
      if (this.$refs.iframelyLink) {
        if (typeof window.iframely !== "undefined") {
          try {
            window.iframely.load(this.$refs.iframelyLink);
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    initCard() {
      this.initPopups();
      this.initEmbeds();
      this.initHeartbeat();
      this.loadIframelyEmbed();
      this.getIframelyResponse();

      if (this.canShowMore) {
        this.setShowMore();
        this.observeEmbedResize();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-resize/dist/vue-resize.css";

[data-tooltip][data-forcewrap]:after {
  white-space: normal;
  z-index: 22;
}

div.ui[class*="9:16"].embed {
  padding-bottom: 177%;
}

div.ui.embed.scrolling {
  overflow: auto !important;
}

.extra.content {
  word-wrap: break-word;

  a.link span span.provider {
    text-transform: capitalize;
  }
}

.ui.right.corner.label {
  z-index: 20;
}

.status.label {
  border-color: transparent;
  a {
    i.icon {
    }
    color: rgba(0, 0, 0, 0.4);
  }

  &.search.stat {
    top: -9px;
    left: 2px;
    i {
      top: 0;
      left: 0;
      font-size: 20px;
      box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.32) !important;
    }
  }
}
</style>

<style lang="scss">
.ui.deliverable.card .card-content {
  display: flex;
  flex-direction: column;

  .carousel {
    height: 100%;

    .ui.image {
      height: 100%;

      img {
        max-height: 100%;
      }
    }
  }

  .VueCarousel {
    height: 100%;
  }

  .VueCarousel-wrapper,
  .VueCarousel-inner {
    height: 100% !important;
  }

  .VueCarousel-navigation {
    position: absolute;
    bottom: 45px;
    width: 100%;
  }

  .VueCarousel-navigation-prev {
    /* transform: translateY(-80%) translateX(400%) !important; */
    top: 0 !important;
    left: 35% !important;
    transform: none !important;
  }
  .VueCarousel-navigation-next {
    top: 0 !important;
    right: 35% !important;
    transform: none !important;
    /* transform: translateY(-80%) translateX(-400%) !important; */
  }

  .VueCarousel-slide {
    .flex-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;

      .slide-image {
        display: block;
        flex: 1 1 auto;
      }
      .pagination-label {
        flex: 0 0 55px;
      }
    }
  }

  .VueCarousel-slide {
    overflow: hidden;
  }
  .VueCarousel-slide .content {
    display: block;
  }
}

.ui.deliverable.card.youtube-card .content.card-header {
  background: linear-gradient(180deg, #ff0000 8px, #fff 8px);
}
.ui.deliverable.card.facebook-card .content.card-header {
  background: linear-gradient(180deg, #476eb1 8px, #fff 8px);
}
.ui.deliverable.card.pinterest-card .content.card-header {
  background: linear-gradient(180deg, #c0001d 8px, #fff 8px);
}
.ui.deliverable.card.rss-card .content.card-header,
.ui.deliverable.card.url-card .content.card-header,
.ui.deliverable.card.google-card .content.card-header {
  background: linear-gradient(180deg, lighten(#f4806f, 5%) 8px, #fff 8px);
}
.ui.deliverable.card.twitter-card .content.card-header {
  background: linear-gradient(180deg, #009dea 8px, #fff 8px);
}

.ui.deliverable.card.mailchimp-card {
  .content.card-header {
    background: linear-gradient(#239ab9 8px, #fff 8px);
  }
}

.ui.deliverable.card.mailerlite-card {
  .content.card-header {
    background: linear-gradient(#3eaf7c 8px, #fff 8px);
  }
}

.ui.deliverable.card.aweber-card {
  .content.card-header {
    background: linear-gradient(180deg, #2072b9 8px, #fff 8px);
  }
}

.ui.deliverable.card.bitly-card {
  .content.card-header {
    background: linear-gradient(180deg, rgba(97, 179, 222, 0.9) 8px, #fff 8px);
  }
}

.ui.deliverable.card.short_io-card {
  .content.card-header {
    background: linear-gradient(180deg, #55af86 8px, #fff 8px);
  }
}

.ui.deliverable.card.campaignmonitor-card {
  .content.card-header {
    background: linear-gradient(#7856ff 8px, #fff 8px);
  }
}

.ui.deliverable.card.convertkit-card {
  .content.card-header {
    background: linear-gradient(#fb6970 8px, #fff 8px);
  }
}
.ui.deliverable.card.constantcontact-card {
  .content.card-header {
    background: linear-gradient(#1856ed 8px, #fff 8px);
  }
}
.ui.deliverable.card.drip-card {
  .content.card-header {
    background: linear-gradient(#f224f2 8px, #fff 8px);
  }
}

.ui.deliverable.card.expertsender-card {
  .content.card-header {
    background: linear-gradient(#552d7e 8px, #fff 8px);
  }
}

.ui.deliverable.card.klaviyo-card {
  .content.card-header {
    background: linear-gradient(#24ad69 8px, #fff 8px);
  }
}

.ui.deliverable.card.sendfox-card {
  .content.card-header {
    background: linear-gradient(#fd6655 8px, #fff 8px);
  }
}

.ui.deliverable.card.tik_tok-card {
  .content.card-header {
    background: linear-gradient(#ee1d52 8px, #fff 8px);
  }
}

.ui.deliverable.card.twitch-card {
  .content.card-header {
    background: linear-gradient(#9146ff 8px, #fff 8px);
  }
}

.ui.deliverable.card.shopify-card {
  .content.card-header {
    background: linear-gradient(#008060 8px, #fff 8px);
  }
}

.ui.deliverable.card.activecampaign-card {
  .content.card-header {
    background: linear-gradient(rgba(57, 110, 226, 1) 8px, #fff 8px);
  }
}

.ui.deliverable.card.linkedin-card {
  .content.card-header {
    background: linear-gradient(#0976b4 8px, #fff 8px);
  }
}

.ui.deliverable.card.madmimi-card {
  .content.card-header {
    background: linear-gradient(#86c3ea 8px, #fff 8px);
  }
}

.ui.deliverable.card.instagram_stories-card {
  .content.card-header {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
}

.ui.deliverable.card.threads-card {
  .content.card-header {
    background: linear-gradient(#3f3f3f 8px, #fff 8px);
  }
}

.ui.deliverable.card.one_signal-card,
.ui.deliverable.card.push_engage-card {
  .content.card-header {
    background: linear-gradient(#e24b4d 8px, #fff 8px);
  }
}

.ui.deliverable.card.omny_studio-card {
  .content.card-header {
    background: linear-gradient(#6313bc 8px, #fff 8px);
  }
}

.ui.deliverable.card.art19-card {
  .content.card-header {
    background: linear-gradient(#1373b6 8px, #fff 8px);
  }
}

.ui.ui.deliverable.card.transistor-card {
  .content.card-header {
    background: linear-gradient(#0c1824, 8px, #fff 8px);
  }
}

.ui.deliverable.card.instagram-card {
  .content.card-header {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
}

@media (min-width: 1200px) {
  .ui.deliverable.card > .content.card-header .ui.statistic > .value,
  .ui.cards > .card > .content.card-header .ui.statistic > .value,
  .ui.deliverable.card
    > .content.card-header
    .ui.statistics
    .statistic
    > .value,
  .ui.cards > .card > .content.card-header .ui.statistics .statistic > .value {
    font-size: 2.29rem !important;
  }
}

.ui.card.deliverable {
  box-shadow: 0px 24px 32px 8px rgba(0, 0, 0, 0.04);

  > .ui.right.corner.label {
    margin-top: 8px;
  }

  .card-header .statistics {
    margin-top: 1.5rem;
  }
  .header .statistic {
    max-width: 56% !important;
    .value {
      color: #0d0d0d;
      font-weight: bold;
      line-height: 40px;
    }
    .label {
      color: #545454;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 12px;
    }
  }

  .statistics-head {
    flex-grow: 0 !important;
  }

  .content {
    overflow: hidden;
  }

  > .content.card-header {
    &.disconnected {
      height: 100px;

      .icon.cloud-slash {
        font-size: 25px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        opacity: 0.75;
      }

      .header {
        font-weight: 600;
      }
    }
    .icon-head img {
      width: 45px !important;
      height: 45px !important;
    }
  }
  > .content.card-header {
    position: relative;
    padding: 1px 25px;
    color: #0d0d0d;
    background: linear-gradient(#e5b586 8px, #fff 8px);
    flex-grow: 0;
    min-height: 100px;
    overflow: visible;

    .header {
      color: #0d0d0d;
    }

    .icon-head {
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #e5b586;
      top: 50%;
      left: 25px;
      position: absolute;
      .icon {
        font-size: 3rem;
        line-height: 1;
      }
    }
  }

  > .content:after {
    display: none !important;
  }

  .ui.statistics {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .ui.statistics-head {
    padding-top: 1.292em;
    padding-bottom: 1.292em;
    overflow: visible;
    height: 80px;

    .ui.wide.popup {
      width: 205px !important;
      top: 100px !important;
      &:before {
        top: 32px !important;
      }
      .statistic {
        width: 100%;
        display: block;
      }
    }
  }

  &:hover {
    .ui.statistics-head .statistic {
      .value {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-position: under;
        text-decoration-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .ui.statistics-head .statistic {
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    padding-right: 0.5em !important;
    padding-left: 0.5em !important;

    > .value {
      color: #0d0d0d !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      white-space: nowrap;
      line-height: 1.8em;
    }
    .label {
      color: #545454 !important;
      font-size: 10px;
      line-height: 1.2em;
      font-weight: 400;
    }
  }

  .card-content {
    position: relative;
    overflow: hidden;
    height: 388px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .card-content > img {
    max-width: 100%;
    height: auto;
  }

  .extra {
    text-align: center;
    position: relative !important;

    .link {
      font-weight: 600;
      font-size: 1rem;
      color: #f4806f !important;
      letter-spacing: 0;
      &:hover {
        opacity: 0.8;
      }
      .icon {
        margin-right: 8px;
      }
    }

    .link-icon {
      color: #f4806f !important;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      top: 50%;
      right: 40px;
      position: absolute;
      font-size: 1.8rem;
      line-height: 1em;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .opener {
    display: block;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    );
    height: 48px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s linear !important;
    :hover {
      background: rgba(0, 0, 0, 0.4);
    }

    .icon {
      color: #ffffff;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
      right: 0;
      position: absolute;
      font-size: 2rem;
      line-height: 1em;
    }
  }

  .content.center.aligned.pagination-label {
    color: #878787;
    font-weight: 600;
    flex-grow: 0;
    flex-shrink: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// .ui.deliverable.card .card-content {
//   height: 100%;
//   max-height: 600px;
// }

@media (min-width: 768px) {
  .ui.deliverable.card .card-content {
    height: 338px;
  }
}
@media (min-width: 1200px) {
  .ui.deliverable.card .card-content {
    height: 338px;
  }
}

@media (min-width: 768px) {
  .ui.deliverable.card .extra {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ui.deliverable.card .extra .link {
    font-size: 1.14285714rem;
  }
}
</style>
